


















































































































































import Vue from "vue";
// eslint-disable-next-line no-unused-vars
import { ComplexObjectPreviewOptions } from "../interfaces";
export default Vue.extend({
  name: "ObjectCard",
  props: {
    options: Object as () => ComplexObjectPreviewOptions,
  },
  data() {
    return {
      ...this.options,
      unsubscribeCompactView: () => {},
    };
  },
  methods: {
    decode: decodeURIComponent,
    collapseExpand() {
      this.isCompactView = !this.isCompactView;
    },
    copyToClipboard() {
      let inputField = this.$refs[
        `coords-${this.complexObject.id}`
      ] as HTMLInputElement;
      inputField = Array.isArray(inputField) ? inputField[0] : inputField;
      inputField.value = this.complexObject.location;
      inputField.select();
      document.execCommand("copy");
      this.toast.add({
        severity: "info",
        detail: this.$t("coordinatesCopied"),
        life: 3000,
      });
    },
  },
  mounted() {
    if (typeof this.compactViewSubscriptor === "function") {
      this.unsubscribeCompactView = this.compactViewSubscriptor(
        this.complexObject.id,
        (val: boolean) => {
          this.isCompactView = val;
        }
      );
    }
  },
  beforeDestroy() {
    this.unsubscribeCompactView();
  },
});
