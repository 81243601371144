






import Vue from "vue";
import ObjectCard from "./components/ObjectCard.vue";
// eslint-disable-next-line no-unused-vars
import { ComplexObject, ComplexObjectPreviewOptions } from "./interfaces";

export default Vue.extend({
  name: "App",
  components: {
    ObjectCard,
  },
  data() {
    const props = this.$parent?.props;
    return {
      options: {
        complexObject: props?.complexObject as ComplexObject,
        actionLabel: props?.options.actionLabel || "",
        actionCallback: props?.options.actionCallback as Function,
        isCompactView: props?.options.isCompactView || (false as Boolean),
        isMapView: props?.options.isMapView || (false as Boolean),
        compactViewSubscriptor: props?.options
          .compactViewSubscriptor as Function,
        toast: props?.options.toast || { add: () => {} },
      } as ComplexObjectPreviewOptions,
    };
  },
});
