var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"complex-object-container p-d-flex p-flex-column",style:({ height: _vm.isCompactView ? 'initial' : 'inherit' })},[_c('div',{staticClass:"complex-object__header",class:{ 'p-raised': _vm.isMapView },on:{"click":function($event){return _vm.actionCallback(_vm.complexObject)}}},[(_vm.complexObject.image)?_c('div',{staticStyle:{"width":"100%","height":"100%","background-size":"cover"},style:({ 'background-image': ("url(" + (_vm.decode(_vm.complexObject.image)) + ")") })}):_c('i',{staticClass:"mdi",class:_vm.complexObject.iconClass,staticStyle:{"font-size":"calc(120rem / var(--bfs))"}})]),_c('div',{staticClass:"complex-object_info-container",class:{ 'map-background p-raised': _vm.isMapView },style:({
      background: _vm.isMapView ? 'none' : 'var(--body-bg)',
      border: _vm.isMapView
        ? '1px solid transparent'
        : '1px solid var(--secondary-color)',
    })},[_c('div',{staticClass:"p-d-flex p-flex-nowrap p-mx-2",staticStyle:{"cursor":"pointer"},style:({
        cursor: _vm.isMapView ? 'auto' : 'pointer',
      }),on:{"click":function($event){!_vm.isMapView ? _vm.collapseExpand() : function () {}}}},[(!_vm.isMapView)?_c('i',{staticClass:"p-mt-2 p-mr-1 mdi mdi-24px",class:_vm.complexObject.iconClass}):_vm._e(),_c('div',{staticClass:"p-d-flex p-flex-column p-mt-1 p-mb-1 ",staticStyle:{"width":"100%","overflow":"hidden"}},[_c('span',{staticClass:"complex-object__title p-mb-1 text-ellipsis",domProps:{"textContent":_vm._s(_vm.complexObject.title)}}),_c('span',{staticClass:"complex-object__subtitle text-ellipsis",domProps:{"textContent":_vm._s(_vm.complexObject.streetAddress)}})]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMapView),expression:"!isMapView"}],staticClass:"p-mt-2 p-ml-2 mdi mdi-24px mdi-menu-right",style:({
          color: 'var(--text-color-secondary)',
          transform: _vm.isCompactView ? 'rotate(0deg)' : 'rotate(90deg)',
          transition: 'all 0.3s',
        })})]),_c('div',{staticClass:"p-d-flex p-mx-2 p-mb-1 p-flex-column p-jc-between",style:({
        borderBottom:
          !_vm.isCompactView && !_vm.isMapView
            ? '1px solid rgba(var(--secondary-color-raw), 0.65)'
            : '1px solid transparent',
      })},[_c('div',{staticClass:"complex-object__actions p-d-flex p-jc-end p-ai-center"},[_c('Button',{staticClass:"p-button-plain p-button-text",staticStyle:{"font-size":"16px"},attrs:{"label":_vm.actionLabel},on:{"click":function($event){return _vm.actionCallback(_vm.complexObject)}}})],1)]),_c('transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCompactView),expression:"!isCompactView"}],staticStyle:{"flex":"1","height":"100%"}},[_c('div',{staticClass:"slide-wrapper"},[_c('div',{staticClass:"p-d-flex p-mx-2 p-mt-1 p-mb-1 p-flex-column"},[_c('div',{staticClass:"complex-object__info"},[_c('div',[_c('span',{domProps:{"textContent":_vm._s(_vm.complexObject.location)}})]),_c('input',{ref:("coords-" + (_vm.complexObject.id)),staticStyle:{"position":"absolute","top":"-calc(40rem / var(--bfs))","opacity":"0"},attrs:{"type":"text"}}),_c('div',{staticClass:"p-d-flex p-ai-center p-jc-end"},[_c('i',{staticClass:"mdi mdi-content-copy",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.copyToClipboard()}}})])]),_c('div',{staticClass:"complex-object__info"},[_c('div',[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('address'))}})]),_c('div',[_c('span',{domProps:{"textContent":_vm._s(
                    [
                      _vm.complexObject.streetAddress,
                      _vm.complexObject.addressLocality,
                      _vm.complexObject.postalCode ]
                      .filter(function (e) { return e; })
                      .join(', ')
                  )}})])])]),_c('div',{staticStyle:{"flex":"1"}})])])]),(_vm.isMapView)?_c('div',{staticClass:"onmap-footter"},[_c('div',{staticClass:"onmap-footter-button",on:{"click":function($event){_vm.isCompactView = !_vm.isCompactView}}},[_c('i',{class:_vm.isCompactView
              ? 'mdi mdi-18px mdi-menu-down'
              : 'mdi mdi-18px mdi-menu-up'})])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }